<template>
  <div>
    <UIExit label="Exit student mode" />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LAYOUT_CONSTANTS from '@src/constants/layout-constant.js'
import UIExit from '@/src/components/UiElements/UIExit.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { SCOPE_LEVELS } from '@src/constants/general-constants'
import { getDefaultRoute } from '@src/utils/generalUtil.js'

export default {
  components: {
    UIExit,
  },
  computed: {
    ...mapState('layout', [
      'currentCampusScope',
      'currentSectionScope',
      'activeRole',
      'currentClassScope',
    ]),
    isLevelChange() {
      return this.currentCampusScope?.id + this.currentSectionScope?.id + this.currentClassScope?.id
    },
  },
  watch: {
    isLevelChange: {
      handler() {
        let route = getDefaultRoute(
          this.activeRole,
          this.currentCampusScope,
          this.currentSectionScope,
        )
        this.$router?.push({ name: route })
      },
    },
  },
  created() {
    this.setLeftBar()
  },
  page: {
    title: 'Profile | Student',
    meta: [
      {
        name: 'description',
        content: 'Student Profile',
      },
    ],
  },

  methods: {
    /**
     * Exit student mode and redirect to student page
     * Remove current user from localStorage
     */
    setLeftBar() {
      this.setLeftbarContent({
        SHOW_SECTION_DROPDOWN_TO: LAYOUT_CONSTANTS.showSectionDropdownTo,
        SHOW_CLASS_DROPDOWN_TO: LAYOUT_CONSTANTS.showClassDropdownTo,
        back: '',
        customeHeader: 'left-bar-class-list',
        routes: [
          // Hide dashboard temp till new implementation
          // {
          //   id: '001',
          //   name: 'Dashboard',
          //   route: { name: 'student-profile-dashboard' },
          //   role: 'super_admin',
          // },
          {
            id: '002',
            name: 'Profile',
            icon: 'users',
            route: { name: 'student-profile-mode' },
            role: ['super_admin', 'section_teacher'],
          },
          {
            id: '003',
            icon: 'expenseManager',
            name: 'Fees',
            role: [
              TENANT_ROLES.SUPER_ADMIN,
              TENANT_ROLES.SECTION_TEACHER,
              TENANT_ROLES.CAMPUS_ADMIN,
            ],
            basedOn: [
              SCOPE_LEVELS.CAMPUS_LEVEL,
              SCOPE_LEVELS.CLASS_LEVEL,
              SCOPE_LEVELS.SECTION_LEVEL,
            ],
            route: { name: 'student-fee-receivable' },
            showChilds: false,
            routesName: ['student-fee-receivable', 'student-fee-received'],
            childs: [
              {
                name: 'STUDENT_PAYABLE',
                route: { name: 'student-fee-receivable' },
                role: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                name: 'STUDENT_PAID',
                route: { name: 'student-fee-received' },
                role: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
            ],
          },
        ],
      })
    },
    ...mapActions('layout', ['setLeftbarContent']),
  },
}
</script>
